import type { FC } from 'react';
import React from 'react';
import { useParams } from 'next/navigation';
import type { Money } from '@wilm/shared-types/product';
import Typography from 'components/commercetools-ui/atoms/typography';
import { CurrencyHelpers } from 'helpers/currencyHelpers';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import useCostsData from '../hooks/useCostsData';
import type { CostsProps } from '../types';

const Costs: FC<CostsProps> = ({
    className,
    order,
    dataReference = 'cart',
    subCostsContainerClassName,
    subCostClassName,
    totalAmountClassName,
    isCartPage
}) => {
    const { locale } = useParams();
    const { loading, zeroTaxed, costsToRender, total, totalAmount } = useCostsData({ dataReference, order });
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });

    const totalAmountClassNames = useClassNames(['flex items-center justify-between pt-12 text-18', totalAmountClassName]);

    const subCostsContainerClassNames = useClassNames(['flex flex-col gap-8 pb-24 pt-16', subCostsContainerClassName]);
    const subCostsClassNames = useClassNames([
        'flex w-full items-center justify-between text-md capitalize lg:text-base',
        subCostClassName
    ]);

    const getItemAmount = (key: string, value: Money): string => {
        if (isCartPage && key === 'tax') return '-';
        return key === 'discount'
            ? `- ${CurrencyHelpers.formatForCurrency(value, locale)}`
            : CurrencyHelpers.formatForCurrency(value, locale);
    };

    return (
        <div className={className}>
            <div className={subCostsContainerClassNames}>
                {costsToRender.map(({ key, label, value }) =>
                    value?.centAmount ? (
                        <div key={key} className={subCostsClassNames}>
                            <Typography asSkeleton={loading}>{label}</Typography>
                            <Typography asSkeleton={loading}>{getItemAmount(key, value)}</Typography>
                        </div>
                    ) : (
                        key === 'tax' &&
                        !isCartPage && (
                            <div className={subCostsClassNames} key={key}>
                                <Typography asSkeleton={loading}>{formatCartMessage({ id: 'tax', defaultMessage: 'Tax' })}</Typography>
                                <Typography asSkeleton={loading}>{CurrencyHelpers.formatForCurrency(value, locale)}</Typography>
                            </div>
                        )
                    )
                )}

                {isCartPage && zeroTaxed && (
                    <div className={subCostsClassNames}>
                        <Typography asSkeleton={loading}>{formatCartMessage({ id: 'tax', defaultMessage: 'Tax' })}</Typography>
                        <Typography asSkeleton={loading}>-</Typography>
                    </div>
                )}
            </div>
            <div className={totalAmountClassNames}>
                <Typography className="font-bold" asSkeleton={loading} data-cy="costs-total-label">
                    {total.label}
                </Typography>
                <Typography className="font-bold" asSkeleton={loading} data-cy="costs-total-amount">
                    {isCartPage
                        ? CurrencyHelpers.formatForCurrency(totalAmount.value, locale)
                        : CurrencyHelpers.formatForCurrency(total.value, locale)}
                </Typography>
            </div>
        </div>
    );
};

export default Costs;

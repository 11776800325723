import type { FC } from 'react';
import React from 'react';
import Typography from 'components/commercetools-ui/atoms/typography';

interface Props {
    orderNumber?: string;
    asSkeleton?: boolean;
}

const OrderPaymentTitle: FC<Props> = () => {
    return (
        <div className="mt-8 flex flex-row flex-wrap pl-16 md:mt-12 md:px-0 md:pl-24 lg:mt-24 lg:pl-30">
            <Typography className="text-14 font-bold text-primary-black md:text-lg" data-cy="order-details-order-payment-title">
                Payments
            </Typography>
        </div>
    );
};

export default OrderPaymentTitle;

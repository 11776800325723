import type { ChangeEvent, FC } from 'react';
import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import type { AssignLearnersPayload } from '@wilm/shared-types/learner/Api';
import { LearnerKeyEnum } from '@wilm/shared-types/learner/Learner';
import type { Learner, LearnerOrderLineItemType, LearnerSeat } from '@wilm/shared-types/learner/Learner';
import { validate } from '@wilm/shared-types/validation-rules';
import { getLineItemLearnerSeatsDuplicatedEmails, initialLearnerSeatFields } from '@wilm/shared-types/validation-rules/learner';
import type { Fields, FieldErrors, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import { FieldType } from '@wilm/shared-types/validation-rules/types';
import toast from 'react-hot-toast';
import Button from 'components/commercetools-ui/atoms/button';
import Input from 'components/commercetools-ui/atoms/input';
import Link from 'components/commercetools-ui/atoms/link';
import Modal from 'components/commercetools-ui/atoms/modal';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import { useFormat } from 'helpers/hooks/useFormat';
import scrollToError from 'helpers/utils/scrollToError';
import { useLearnerDataContext } from 'providers/learner/data';

export interface Props {
    isOpen: boolean;
    close: () => void;
    selectedLineItem?: LearnerOrderLineItemType;
}

const AssignLearnersModal: FC<Props> = ({ isOpen, close, selectedLineItem }) => {
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [fieldErrors, setFieldErrors] = useState<Record<string, FieldErrors>>({});
    const [assignLoading, setAssignLoading] = useState<boolean>(false);

    const { formatMessage: formatFieldLabelsMessage } = useFormat({ name: 'field-labels' });
    const { formatMessage: formatFieldErrorsMessage } = useFormat({ name: 'field-errors' });
    const { formatMessage: formatCommonMessage } = useFormat({ name: 'common' });

    const downloadTemplateCSVText = useMemo(() => {
        return formatCommonMessage({ id: 'download.template.csv', defaultMessage: 'Download template CSV' });
    }, [formatCommonMessage]);

    const { assignLearners, learnerSettings, handleTemplateGeneration } = useLearnerDataContext();

    const initialSeatsFields = useMemo(() => {
        const seatsFields = {} as Record<string, Fields>;
        const availableSeats = +(selectedLineItem?.seats.available ?? '0');
        for (let i = 0; i < availableSeats; i++) {
            seatsFields[`seat-${i}`] = initialLearnerSeatFields;
        }
        return seatsFields;
    }, [selectedLineItem]);

    const [seatsFields, setSeatsFields] = useState(initialSeatsFields);

    const hasAtLeastOneFilledInput = useMemo(() => {
        return Object.values(seatsFields).some(seatFields => {
            return Object.values(seatFields).some(seatField => {
                return seatField.value !== '';
            });
        });
    }, [seatsFields]);

    const hasAvailableSeats = selectedLineItem?.seats?.available ? selectedLineItem.seats.available > 0 : false;

    useEffect(() => {
        setSeatsFields(initialSeatsFields);
    }, [initialSeatsFields]);

    const handleFieldChange = useCallback(
        (seatFieldIndex: keyof Record<string, Fields>, seatFieldName: keyof Fields, value: string) => {
            setSeatsFields(prevSeatsFields => ({
                ...prevSeatsFields,
                [seatFieldIndex]: {
                    ...prevSeatsFields[seatFieldIndex],
                    [seatFieldName]: {
                        ...prevSeatsFields[seatFieldIndex][seatFieldName],
                        value
                    } as StringFieldDefinition
                }
            }));
        },
        [setSeatsFields]
    );

    const resetForm = useCallback(() => {
        setParsedData([]);
        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
        setCsvPrefillErrors('');
        setErrorMessage(null);
        setFieldErrors({});
        setSeatsFields(initialSeatsFields);
    }, [setErrorMessage, setFieldErrors, setSeatsFields, initialSeatsFields]);

    const handleClose = useCallback(() => {
        resetForm();
        close();
    }, [resetForm, close]);

    const handleSubmit = useCallback(
        async (e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
            setAssignLoading(true);
            setErrorMessage(null);

            const newLearnerSeats: AssignLearnersPayload['learnerSeats'] = [];

            const seatsFieldsTrimmed = {} as Record<string, Fields>;
            for (const [lineItemId, seatFields] of Object.entries(seatsFields)) {
                seatFields.email.value = (seatFields.email.value as string).trim();
                seatFields.firstName.value = (seatFields.firstName.value as string).trim();
                seatFields.lastName.value = (seatFields.lastName.value as string).trim();

                seatsFieldsTrimmed[lineItemId] = seatFields;

                const email = seatFields.email.value;
                const firstName = seatFields.firstName.value;
                const lastName = seatFields.lastName.value;

                if (email || firstName || lastName) {
                    const seat: Omit<LearnerSeat, 'provisioning'> = {
                        learner: {
                            firstName,
                            lastName,
                            email
                        }
                    };
                    newLearnerSeats.push(seat);
                }
            }

            setSeatsFields(seatsFieldsTrimmed);

            if (newLearnerSeats.length === 0) {
                setErrorMessage(
                    formatCommonMessage({ id: 'error.learner.no.learners', defaultMessage: 'Please add at least one learner' })
                );
                setAssignLoading(false);
                return;
            }

            const allLearnerSeats = [...(selectedLineItem?.learnerSeats ?? []), ...newLearnerSeats];

            const duplicatedEmails = getLineItemLearnerSeatsDuplicatedEmails(allLearnerSeats);

            // Validate the form
            const validationErrors = {} as Record<string, FieldErrors>;

            Object.entries(seatsFields).forEach(([seatFieldIndex, seatFields]) => {
                Object.entries(seatFields).forEach(([seatFieldName, seatField]) => {
                    let fieldError = validate(seatField, seatFields);
                    if (seatField.name === 'email' && duplicatedEmails.includes(seatField.value as string)) {
                        fieldError = {
                            message: 'error.learner.duplicated.email'
                        };
                    }
                    if (Object.keys(fieldError).length > 0) {
                        validationErrors[seatFieldIndex] = validationErrors[seatFieldIndex] ?? {};
                        validationErrors[seatFieldIndex][seatFieldName] = fieldError;
                    }
                });
            });

            setFieldErrors(validationErrors);

            if (Object.keys(validationErrors).length > 0) {
                setAssignLoading(false);
                scrollToError(Object.keys(validationErrors)[0]);
                return;
            }

            const assignResult = await assignLearners(selectedLineItem!.lineItemId, newLearnerSeats);

            console.info('---> assignResult', assignResult);

            setAssignLoading(false);

            const successMarkdownReplaced = learnerSettings?.assigningToastSuccessMessage?.replaceAll(
                '{productName}',
                selectedLineItem?.name ?? ''
            );

            const markdownElement = <Markdown markdown={successMarkdownReplaced} />;

            if (!assignResult.isError) {
                toast.success(markdownElement);
                handleClose();
            }

            if (assignResult.isError) {
                if (assignResult.errorMessage) {
                    setErrorMessage(assignResult.errorMessage);
                }
                if (assignResult.fieldErrors) {
                    setFieldErrors(assignResult.fieldErrors);
                }
            }
        },
        [selectedLineItem, seatsFields, assignLearners, formatCommonMessage, handleClose, learnerSettings?.assigningToastSuccessMessage]
    );

    const fileInputRef = useRef<HTMLInputElement>(null);
    const [parsedData, setParsedData] = useState<Learner[]>([]);
    const [csvPrefillErrors, setCsvPrefillErrors] = useState<string>('');

    function isParsedDataValid(data: Learner[]): boolean {
        return Array.isArray(data) && data.length > 0;
    }

    useEffect(() => {
        if (isParsedDataValid(parsedData)) {
            const loopEnd = Math.min(parsedData.length, Object.keys(seatsFields).length);

            const newSeatsFields = { ...seatsFields };

            for (let i = 0; i < loopEnd; i++) {
                const parsedPerson = parsedData[i];

                newSeatsFields[`seat-${i}`] = {
                    firstName: {
                        ...newSeatsFields[`seat-${i}`].firstName,
                        value: parsedPerson.firstName
                    } as StringFieldDefinition,
                    lastName: {
                        ...newSeatsFields[`seat-${i}`].lastName,
                        value: parsedPerson.lastName
                    } as StringFieldDefinition,
                    email: {
                        ...newSeatsFields[`seat-${i}`].email,
                        value: parsedPerson.email
                    } as StringFieldDefinition
                };
            }

            setSeatsFields(newSeatsFields);
        }
    }, [parsedData]);

    useEffect(() => {
        if (!csvPrefillErrors) {
            return;
        }

        setSeatsFields(initialSeatsFields);

        if (fileInputRef.current?.value) {
            fileInputRef.current.value = '';
        }
    }, [csvPrefillErrors, fileInputRef.current]);

    // Function to parse CSV content
    const parseCSV = (csvContent: any) => {
        const lines: string[] = csvContent.split('\n'); // Split by new line
        //remove last line if empty
        if (lines[lines.length - 1].trim() === '') {
            lines.pop();
        }

        const headers = lines?.[0]?.split(',') ?? []; // First line contains headers
        const learners = [] as Learner[];

        const expectedHeaders = Array.from(Object.values(LearnerKeyEnum));

        // Ensure valid headers
        if (headers.length !== expectedHeaders.length) {
            setCsvPrefillErrors('Please upload a valid .csv file or download the template');
            return [] as Learner[];
        }

        const validHeaders: LearnerKeyEnum[] = [];
        for (let i = 0; i < expectedHeaders.length; i++) {
            const header = headers[i].trim();
            const expectedHeader = expectedHeaders[i];
            if (expectedHeader === header) {
                validHeaders.push(expectedHeader);
            } else {
                setCsvPrefillErrors('Please upload a valid .csv file or download the template');
                return [] as Learner[];
            }
        }

        // Loop through each learnerRow, starting from the second line (index 1)
        for (let i = 1; i < lines.length; i++) {
            const learnerRow = lines[i].split(',');
            // Ensure valid learnerRow length
            if (learnerRow.length !== validHeaders.length) {
                setCsvPrefillErrors('Please upload a valid .csv file or download the template');
                return [] as Learner[];
            }

            const learner: Learner = {} as Learner;
            // Loop through each header and assign the value to the learner object
            for (let index = 0; index < validHeaders.length; index++) {
                const header = validHeaders[index];
                const value = learnerRow[index]?.trim();

                learner[header] = value;
            }
            learners.push(learner);
        }

        return learners;
    };

    const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event?.target?.files?.[0];

        const isCSV =
            file &&
            (file.type === 'text/csv' ||
                file.type === 'application/csv' ||
                file.type === 'application/vnd.ms-excel' ||
                file.name.toLowerCase().endsWith('.csv'));

        if (file && !isCSV) {
            if (fileInputRef.current?.value) {
                fileInputRef.current.value = '';
            }
            setCsvPrefillErrors('Please make sure that the file is in .csv format');
            return;
        }

        if (file) {
            const reader = new FileReader();

            reader.onload = e => {
                const content = e?.target?.result;
                const data = parseCSV(content);

                setParsedData(data);
            };

            reader.readAsText(file);
            setCsvPrefillErrors('');
            setSeatsFields(initialSeatsFields);
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            className="relative w-[90%] rounded-md bg-white"
            style={{ content: { maxWidth: '1400px' } }}
            preventScroll={true}
        >
            <div className="max-h-screen overflow-auto p-15 md:p-40">
                <div className="grid grid-cols-1 bg-white lg:grid-cols-12">
                    <div className="col-span-7">
                        <h3 className="">{selectedLineItem?.name}</h3>
                        <div className="mt-8 text-sm">
                            <Markdown markdown={learnerSettings.assigningModalMessage} />
                        </div>
                    </div>
                    <div className="col-span-5 mt-20 sm:grid sm:grid-cols-8 lg:mt-0">
                        <input
                            ref={fileInputRef}
                            type="file"
                            id="file-input"
                            accept=".csv"
                            onChange={handleFileUpload}
                            className="hidden"
                        />
                        {/* Display the selected file name */}
                        {fileInputRef?.current?.files?.[0] ? (
                            <div className="col-span-3 flex h-fit flex-wrap text-sm sm:mt-8">
                                <span className="max-w-full break-words text-gray-700">{fileInputRef.current.files[0].name}</span>
                                <div className="flex-grow text-right">
                                    <button onClick={resetForm} type="button" className="underline">
                                        {formatCommonMessage({ id: 'clear.all', defaultMessage: 'Clear all' })}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="col-span-3 sm:text-right">
                                <span className="text-sm text-gray-500">
                                    {formatCommonMessage({ id: 'no.file.chosen', defaultMessage: 'No file chosen' })}
                                </span>
                            </div>
                        )}
                        <div className="col-span-2 my-10 sm:my-0">
                            <label
                                htmlFor="file-input"
                                className="focus-visible:border-white-100 block rounded-md border border-btn-secondary-border bg-btn-secondary-bg p-8 text-center text-sm leading-tight text-btn-secondary-size transition hover:shadow-bottom focus-visible:border focus-visible:outline focus-visible:outline-offset-[3px] active:shadow-button active:outline-1 active:outline-offset-0 active:outline-gray-300 disabled:border-btn-secondary-dis disabled:text-btn-secondary-dis active:disabled:shadow-none sm:mx-10"
                            >
                                {formatCommonMessage({ id: 'upload.csv.file', defaultMessage: 'Upload CSV file' })}
                            </label>
                        </div>
                        <div className="col-span-3">
                            <Link
                                onClick={handleTemplateGeneration}
                                variant="primary"
                                className="font-bold !text-learner-accent-1"
                                title={downloadTemplateCSVText}
                                link={'#'}
                            >
                                {downloadTemplateCSVText}
                            </Link>
                        </div>

                        {csvPrefillErrors && (
                            <p className="col-span-8 mt-10 w-full text-sm text-input-error sm:text-right">{csvPrefillErrors}</p>
                        )}
                    </div>
                </div>

                {/* list of assigned learners */}
                {selectedLineItem?.learnerSeats.map((learnerSeat, index) => (
                    <div className="flex flex-col gap-20 border-b py-16 lg:flex-row lg:items-end" key={'learner-' + index}>
                        <div className="w-20 font-bold">{index + 1}</div>
                        <div className="flex-1">
                            <p className="mb-4 text-sm">{formatCommonMessage({ id: 'firstName', defaultMessage: 'First name' })}</p>
                            <p className="break-all font-bold">{learnerSeat.learner.firstName}</p>
                        </div>
                        <div className="flex-1">
                            <p className="mb-4 text-sm">{formatCommonMessage({ id: 'lastName', defaultMessage: 'Last name' })}</p>
                            <p className="break-all font-bold">{learnerSeat.learner.lastName}</p>
                        </div>
                        <div className="flex-1">
                            <p className="mb-4 text-sm">{formatCommonMessage({ id: 'emailAddress', defaultMessage: 'Email Address' })}</p>
                            <p className="break-all font-bold">{learnerSeat.learner.email}</p>
                        </div>

                        <div className="w-120 text-md">{learnerSeat.provisioning?.state}</div>
                    </div>
                ))}

                <form autoComplete="off" onSubmit={handleSubmit} noValidate>
                    {/* list of available seats */}
                    {Object.entries(seatsFields).map(([seatFieldIndex, seatFields]) => (
                        <div key={seatFieldIndex} className="flex flex-col gap-20 border-b pb-24 pt-16 lg:flex-row" id={seatFieldIndex}>
                            {Object.values(seatFields).map(seatField => {
                                // If the field is hidden, don't render it
                                if (seatField?.showOnPredicate?.(seatFields) === false) {
                                    return null;
                                }

                                // Check if the field is required
                                const isRequired =
                                    seatField.validation.required ?? seatField.validation.requiredPredicate?.(seatFields) ?? false;

                                const errorMessage = fieldErrors?.[seatFieldIndex]?.[seatField.name]?.message;

                                const formattedErrorMessage = errorMessage
                                    ? formatFieldErrorsMessage({
                                          id: errorMessage,
                                          defaultMessage: errorMessage
                                      })
                                    : '';

                                // Render the field based on its type
                                if (seatField.type === FieldType.STRING) {
                                    const learnerSeatsNumber = selectedLineItem?.learnerSeats ? selectedLineItem?.learnerSeats.length : 0;

                                    const number = parseInt(seatFieldIndex.split('-')[1], 10) + 1 + learnerSeatsNumber;
                                    return (
                                        <>
                                            {seatField.name === 'firstName' && <p className="w-20 font-bold lg:mt-44">{number}</p>}
                                            <div key={`${seatField.name}-${seatFieldIndex}`} className="flex-1">
                                                <Input
                                                    id={`learner-seat-field-${seatField.name}-${seatFieldIndex}`}
                                                    name={seatField.name}
                                                    type="text"
                                                    label={formatFieldLabelsMessage({
                                                        id: `learner.${seatField.name}.label`,
                                                        defaultMessage: seatField.name
                                                    })}
                                                    placeholder={seatField.placeholder}
                                                    value={seatField.value}
                                                    minLength={seatField.validation.minLength}
                                                    maxLength={seatField.validation.maxLength}
                                                    required={isRequired}
                                                    onChange={e => {
                                                        handleFieldChange(seatFieldIndex, seatField.name, e.target.value);
                                                    }}
                                                    errorMessage={formattedErrorMessage}
                                                    data-cy={`learner-seat-field-${seatField.name}-${seatFieldIndex}`}
                                                />
                                            </div>

                                            {seatField.name === 'email' && (
                                                <p className="w-120 text-md text-learner-accent lg:mt-44">
                                                    {formatCommonMessage({ id: 'pending', defaultMessage: 'Pending' })}
                                                </p>
                                            )}
                                        </>
                                    );
                                }
                            })}
                        </div>
                    ))}
                    {errorMessage && <p className="mt-30 text-right text-input-error">{errorMessage}</p>}

                    <div className="mt-30 justify-end lg:flex">
                        {/* clear the form */}
                        {hasAvailableSeats && hasAtLeastOneFilledInput && (
                            <Button
                                variant="secondary"
                                onClick={e => {
                                    e.preventDefault();
                                    resetForm();
                                }}
                                className="mb-14 w-full rounded-md md:rounded-lg lg:mb-0 lg:mr-10 lg:w-auto"
                            >
                                {formatCommonMessage({ id: 'clear.form', defaultMessage: 'Clear form' })}
                            </Button>
                        )}

                        {/* close the modal */}
                        <Button
                            variant="secondary"
                            onClick={handleClose}
                            className="mb-14 w-full rounded-md md:rounded-lg lg:mb-0 lg:mr-10 lg:w-auto"
                        >
                            {hasAvailableSeats
                                ? formatCommonMessage({ id: 'cancel', defaultMessage: 'Cancel' })
                                : formatCommonMessage({ id: 'close', defaultMessage: 'Close' })}
                        </Button>

                        {/* save learners */}
                        {hasAvailableSeats && (
                            <Button
                                variant="primary"
                                type="submit"
                                className="w-full rounded-md md:rounded-lg lg:w-auto"
                                loading={assignLoading}
                            >
                                {formatCommonMessage({ id: 'save.learners', defaultMessage: 'Save learner assignments' })}
                            </Button>
                        )}
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default AssignLearnersModal;

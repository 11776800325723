import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { CurrencyHelpers } from 'helpers/currencyHelpers';

export interface Props {
    order: Order;
}
const OrderPaymentItem: FC<Props> = ({ order }) => {
    const paymentInfo = order.payments
        ?.map(payment => {
            const createdDate = new Date(JSON.parse(payment.debug ?? '').createdAt);

            const day = String(createdDate.getDate()).padStart(2, '0');
            const month = String(createdDate.getMonth() + 1).padStart(2, '0');
            const year = createdDate.getFullYear();

            return {
                paymentMethod: payment.paymentMethod,
                createdDate: `${day}/${month}/${year}`,
                amount: CurrencyHelpers.formatForCurrency(
                    `${payment?.amountPlanned?.centAmount}`,
                    `${payment.amountPlanned.fractionDigits}`,
                    payment.amountPlanned.currencyCode
                ),
                paymentStatus: payment.transactionStatus,
                id: payment.id
            };
        })
        .filter(p => p.paymentStatus !== '' && p.paymentMethod.toLowerCase() !== 'invoice');

    return (
        <>
            {paymentInfo?.map((info, index) => {
                return (
                    <div
                        key={`${info.id}`}
                        className={`flex px-16 md:px-24 lg:flex-col lg:px-30 xl:flex-row ${
                            index === 0 ? 'mt-30 border-b-0' : 'border-t-0'
                        }`}
                    >
                        <div className="w-full border border-r-0 p-16 md:p-24 lg:mx-0 lg:p-30">
                            <div>
                                <span className="font-bold">Payment date:</span> {info.createdDate}
                            </div>
                        </div>
                        <div className="w-full border border-x-0 p-16 md:p-24 lg:mx-0 lg:p-30">
                            <div>
                                <span className="font-bold">Payment amount:</span> {info.amount}
                            </div>
                        </div>
                        <div className="w-full border border-l-0 p-16 md:p-24 lg:mx-0 lg:p-30">
                            <div>
                                <span className="font-bold">Payment status:</span> {info.paymentStatus}
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default OrderPaymentItem;

import React, { useCallback, useMemo } from 'react';
import { useRouter, useSearchParams } from 'next/navigation';
import Skeleton from 'react-loading-skeleton';
import Button from 'components/commercetools-ui/atoms/button';
import Link from 'components/commercetools-ui/atoms/link';
import Typography from 'components/commercetools-ui/atoms/typography';
import CheckoutProvider from 'components/commercetools-ui/organisms/checkout/provider';
import LoqateProvider from 'context/loqate';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import useClassNames from 'helpers/hooks/useClassNames';
import { useFormat } from 'helpers/hooks/useFormat';
import { useBrandSettingsContext } from 'providers/brand-settings';
import { useAccount } from 'frontastic';
import AccountTabsMobile from './account-atoms/account-tabs-mobile';
import Addresses from './sections/addresses';
import AddressForm from './sections/addresses/address-form';
import CustomerSupport from './sections/customer-support';
import MyAccount from './sections/my-account';
import ChangePasswordForm from './sections/my-account/forms/change-password-form';
import DeleteAccountForm from './sections/my-account/forms/delete-account-form';
import PersonalInfoForm from './sections/my-account/forms/personal-info-form';
import Orders from './sections/orders';
import PaymentMethods from './sections/payment-methods';
import PaymentAdd from './sections/payment-methods/payment-add';
import Subscriptions from './sections/subscriptions';
import SubscriptionPage from './sections/subscriptions/subscription-page';
import Loqate from '../../../headless/loquate';
import Wishlist from '../wishlist';

export interface AccountTab {
    name: string;
    href: string;
    isActive: boolean;
    dataCy: string;
}
export interface FAQ {
    question: string;
    answer: string;
}
export interface AccountDetailsProps {
    accountDashboardStaticText?: string;
    accountSubscriptionsStaticText?: string;
    phoneNumber: string;
    workingHoursWeekdays: string;
    workingHoursWeekends: string;
    email: string;
    addressLine: string;
    cityAndPostalCode: string;
    country: string;
    faqs: FAQ[];
    learningHubTitle: string;
    learningHubCopy: string;
    disableSubscriptionsLink: boolean;
    accountBillingAddressMessage: string;
}

const AccountDetails: React.FC<AccountDetailsProps> = ({
    accountDashboardStaticText,
    accountSubscriptionsStaticText,
    phoneNumber,
    workingHoursWeekdays,
    workingHoursWeekends,
    email,
    addressLine,
    cityAndPostalCode,
    country: organizationCountry,
    faqs,
    learningHubTitle,
    learningHubCopy,
    disableSubscriptionsLink,
    accountBillingAddressMessage
}) => {
    const router = useRouter();

    const searchParams = useSearchParams();
    const { wishlist } = useBrandSettingsContext();

    const verify = searchParams.get('verify');
    const hash = searchParams.get('hash') ?? '';
    const id = searchParams.get('id');

    const { logout } = useAccount();
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });
    const isLoading = useMemo(() => !!verify, [verify]);

    const handleLogout = async () => {
        await logout();

        track({ type: TagsActionType.CLEAR, payload: {} });

        router.replace('/login');
    };

    const tabs = useMemo<AccountTab[]>(() => {
        return [
            {
                name: formatAccountMessage({ id: 'account', defaultMessage: 'Account' }),
                href: '?',
                isActive: hash === '',
                dataCy: 'my-account-tab-account'
            },
            {
                name: formatAccountMessage({ id: 'addresses', defaultMessage: 'Addresses' }),
                href: '?hash=addresses',
                isActive: hash === 'addresses',
                dataCy: 'my-account-tab-addresses'
            },
            {
                name: formatAccountMessage({ id: 'payment.methods', defaultMessage: 'Payment methods' }),
                href: '?hash=payment',
                isActive: hash === 'payment',
                dataCy: 'my-account-tab-payment'
            },
            {
                name: formatAccountMessage({ id: 'orders', defaultMessage: 'Orders' }),
                href: '?hash=orders',
                isActive: hash === 'orders',
                dataCy: 'my-account-tab-orders'
            },

            ...(!disableSubscriptionsLink
                ? [
                      {
                          name: formatAccountMessage({ id: 'subscriptions', defaultMessage: 'Subscriptions' }),
                          href: '?hash=subscriptions',
                          isActive: hash === 'subscriptions',
                          dataCy: 'my-account-tab-subscriptions'
                      }
                  ]
                : []),

            ...(wishlist?.enabled
                ? [
                      {
                          name: formatAccountMessage({ id: 'wishlist', defaultMessage: 'Wishlist' }),
                          href: '?hash=wishlist',
                          isActive: hash === 'wishlist',
                          dataCy: 'my-account-tab-wishlist'
                      }
                  ]
                : [])
        ];
    }, [formatAccountMessage, hash, wishlist?.enabled]);

    const accountPagesRef = useMemo(() => {
        return {
            'edit-personal-info': <PersonalInfoForm />,
            'change-password': <ChangePasswordForm />,
            'delete-account': <DeleteAccountForm />
        };
    }, []);

    const { customerSupportLink, digitalHubLink } = useBrandSettingsContext();

    const account = useMemo(
        () =>
            accountPagesRef[id as keyof typeof accountPagesRef] ?? (
                <MyAccount
                    isLoading={isLoading}
                    staticText={accountDashboardStaticText}
                    learningHubCopy={learningHubCopy}
                    learningHubTitle={learningHubTitle}
                    digitalHubLink={digitalHubLink}
                />
            ),
        [id, isLoading, accountPagesRef, accountDashboardStaticText, learningHubCopy, learningHubTitle]
    );

    const addresses = useMemo(() => {
        return id?.startsWith('address') ? (
            <LoqateProvider>
                <AddressForm editedAddressId={id?.split(/_(.*)/s)[1]} />
            </LoqateProvider>
        ) : (
            <Addresses accountBillingAddressMessage={accountBillingAddressMessage} />
        );
    }, [id]);

    const orders = useMemo(() => {
        return <Orders orderNumber={id?.toString().replace('order_', '') ?? undefined} />;
    }, [id]);

    const subscriptions = useMemo(() => {
        return id?.startsWith('subscription') ? (
            <SubscriptionPage orderId={id.split('_')[1]} digitalHubLink={digitalHubLink} customerSupportLink={customerSupportLink} />
        ) : (
            <Subscriptions staticText={accountSubscriptionsStaticText} />
        );
    }, [id, digitalHubLink, customerSupportLink, accountSubscriptionsStaticText]);

    const paymentPagesRef = useMemo(() => {
        return {
            add: (
                <CheckoutProvider>
                    <PaymentAdd />
                </CheckoutProvider>
            )
        };
    }, []);
    const Payment = useMemo(
        () => paymentPagesRef[id?.split('-')[0] as keyof typeof paymentPagesRef] ?? <PaymentMethods />,
        [id, paymentPagesRef]
    );

    const mapping = {
        '': account,
        addresses: addresses,
        orders: orders,
        payment: Payment,
        subscriptions: subscriptions,
        wishlist: <Wishlist style="boxed" showEmpty />,
        support: (
            <CustomerSupport
                phoneNumber={phoneNumber}
                workingHoursWeekdays={workingHoursWeekdays}
                workingHoursWeekends={workingHoursWeekends}
                email={email}
                addressLine={addressLine}
                cityAndPostalCode={cityAndPostalCode}
                country={organizationCountry}
                faqs={faqs}
            />
        )
    };

    const contentTitle = useMemo(() => {
        const tabIndex = tabs?.findIndex(tab => tab.isActive);
        if (tabs[tabIndex]) return tabs[tabIndex].name ?? '';
        else return '';
    }, [tabs]);

    const Content = mapping[hash as keyof typeof mapping];

    const tabButtonClassNames = useClassNames(['whitespace-nowrap', isLoading ? 'cursor-default' : '']);

    const tabButtonLabelClassNames = useCallback((tab: AccountTab) => {
        return `hover:underline text-primary-black text-16 leading-8 ${tab.isActive ? 'font-bold underline' : ''}`;
    }, []);

    return (
        <>
            <div className="relative flex bg-neutral-100">
                <Loqate />
                <div className="sticky top-0  self-start md:h-[calc(100vh-141px)] md:w-[34%]">
                    <div className="hidden size-full flex-col justify-between pt-24 md:flex lg:pt-44">
                        <div className="px-28 lg:px-40">
                            <Typography as="h3" className="text-lg font-bold text-primary-black">
                                {formatAccountMessage({ id: 'my.account', defaultMessage: 'My account' })}
                            </Typography>
                            <div className="grid pt-20">
                                {tabs.map(tab => (
                                    <Link
                                        link={isLoading ? '' : tab.href}
                                        key={tab.name}
                                        className={tabButtonClassNames}
                                        data-cy={tab.dataCy}
                                    >
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <Typography className={tabButtonLabelClassNames(tab)}>{tab.name}</Typography>
                                        )}
                                    </Link>
                                ))}
                                {customerSupportLink && (
                                    <Link
                                        link={isLoading ? '' : customerSupportLink}
                                        target="_blank"
                                        className={tabButtonClassNames}
                                        data-cy="my-account-customer-support-link"
                                    >
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <Typography className="text-16 leading-8 text-primary-black hover:underline">
                                                {formatAccountMessage({
                                                    id: 'customer.support',
                                                    defaultMessage: 'Customer support'
                                                })}
                                            </Typography>
                                        )}
                                    </Link>
                                )}

                                {digitalHubLink && (
                                    <Link
                                        link={isLoading ? '' : digitalHubLink}
                                        target="_blank"
                                        className={tabButtonClassNames}
                                        data-cy="my-account-digital-hub-link"
                                    >
                                        {isLoading ? (
                                            <Skeleton />
                                        ) : (
                                            <Typography className="text-16 leading-8 text-primary-black hover:underline">
                                                {formatAccountMessage({
                                                    id: 'hub.link',
                                                    defaultMessage: 'Learning Hub'
                                                })}
                                            </Typography>
                                        )}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <div className="p-28 lg:p-40">
                            {isLoading ? (
                                <Skeleton className="h-30" />
                            ) : (
                                <Button onClick={handleLogout} variant="secondary" size="full">
                                    {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className="border-checkout-border flex w-full flex-col md:border-l lg:items-start">
                    <div className="w-full pb-30">
                        <div className="mt-20 px-16">
                            <div className="block md:hidden">
                                <Typography as="h2" className="text-18 text-primary-black">
                                    {formatAccountMessage({ id: 'my.account', defaultMessage: 'My account' })}
                                </Typography>
                            </div>

                            {contentTitle && (
                                <AccountTabsMobile
                                    contentTitle={contentTitle}
                                    customerSupportLink={customerSupportLink}
                                    digitalHubLink={digitalHubLink}
                                    hash={hash}
                                    tabs={tabs}
                                    className="mt-20"
                                />
                            )}
                        </div>
                        <div key={hash}>{Content}</div>
                    </div>
                </div>
            </div>
            <div className="px-16 pb-30 md:hidden">
                {isLoading ? (
                    <Skeleton className="h-30" />
                ) : (
                    <Button
                        onClick={handleLogout}
                        variant="secondary"
                        className="w-full rounded-md border border-primary-black px-36 py-2.5 text-16 leading-6"
                    >
                        {formatAccountMessage({ id: 'sign.out', defaultMessage: 'Sign out' })}
                    </Button>
                )}
            </div>
        </>
    );
};
export default AccountDetails;

import React, { useContext, useMemo, useCallback, useState, useEffect } from 'react';
import { useRouter } from 'next/navigation';
import type { SDKResponse } from '@commercetools/frontend-sdk';
import type { Order } from '@wilm/shared-types/cart/Order';
import type { SWRResponse } from 'swr';
import useSWR from 'swr';
import { sdk } from 'sdk';

const OrderHistoryContext = React.createContext(
    {} as {
        loading: boolean;
        selectedOrder: Order | undefined;
        setSelectedOrder: (order: Order | undefined) => void;
        fullOrderHistory: FullOrderHistory | undefined;
        currentPage: number;
        totalPages: number;
        moveToPageHandler: (page: number) => void;
    }
);

const OFFSET_DEFAULT_VALUE = 0;
const LIMIT_DEFAULT_VALUE = 15;

interface OrderHistoryProviderProps {
    orderNumber?: string;
    children?: React.ReactNode;
}

interface FullOrderHistory {
    orders: Order[];
    limit: number;
    total: number;
    offset: number;
}

const OrderHistoryProvider: React.FC<OrderHistoryProviderProps> = ({ orderNumber, children }) => {
    const [selectedOrder, setSelectedOrder] = useState<Order | undefined>(undefined);
    const router = useRouter();

    useEffect(() => {
        if (orderResponse.isValidating) {
            return;
        }

        if (selectedOrder) {
            router.push('/account/?hash=orders&id=order_' + selectedOrder.orderId, { scroll: false });
        } else {
            router.push('/account/?hash=orders', { scroll: false });
        }
    }, [selectedOrder]);

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const [pagination, setPagination] = useState({
        offset: OFFSET_DEFAULT_VALUE
    });

    const ordersFetcher = (offset?: number) => {
        let newOffset = pagination.offset;

        if (typeof offset === 'number') {
            newOffset = offset;
        }

        return sdk.callAction<FullOrderHistory>({
            actionName: 'cart/getOrders',
            payload: { offset: newOffset, limit: LIMIT_DEFAULT_VALUE }
        });
    };

    const orderFetcher = () =>
        sdk.callAction<FullOrderHistory>({
            actionName: 'cart/getOrders',
            payload: { orderNumber }
        });
    const orderResponse: SWRResponse<SDKResponse<FullOrderHistory>> = useSWR<SDKResponse<FullOrderHistory>>(
        'action/cart/getOrder',
        orderFetcher,
        { revalidateOnMount: true }
    );

    useEffect(() => {
        if (orderNumber && !orderResponse.isValidating && orderResponse.data && !orderResponse.data?.isError) {
            const order = orderResponse.data.data.orders[0];
            if (order) {
                setSelectedOrder(order);
            }
        }
    }, [orderResponse.data]);

    const fullOrderHistoryResponse: SWRResponse<SDKResponse<FullOrderHistory>> = useSWR<SDKResponse<FullOrderHistory>>(
        'action/cart/getOrders',
        ordersFetcher,
        { revalidateOnMount: true }
    );

    const loading = useMemo(() => fullOrderHistoryResponse.isValidating, [fullOrderHistoryResponse.isValidating]);

    const fullOrderHistory = useMemo(
        () => (fullOrderHistoryResponse.data?.isError ? undefined : fullOrderHistoryResponse.data?.data),
        [fullOrderHistoryResponse.data]
    );

    const { currentPage, totalPages } = useMemo(() => {
        const pagination = {
            currentPage: 1,
            totalPages: 0
        };
        if (fullOrderHistory) {
            pagination.currentPage = Math.ceil(fullOrderHistory.offset / fullOrderHistory.limit) + 1;
            pagination.totalPages = Math.ceil(fullOrderHistory.total / fullOrderHistory.limit) ?? 1;
        }
        return pagination;
    }, [fullOrderHistory]);

    const moveToPageHandler = useCallback(
        (page: number) => {
            if (!loading) {
                const newPagination = { ...pagination };
                newPagination.offset = (page - 1) * LIMIT_DEFAULT_VALUE;
                setPagination(newPagination);
                void fullOrderHistoryResponse.mutate(ordersFetcher(newPagination.offset));
                window?.scrollTo({ top: 0, behavior: 'smooth' });
            }
        },
        [loading, pagination, fullOrderHistoryResponse]
    );

    const value = useMemo(
        () => ({
            loading,
            selectedOrder,
            currentPage,
            totalPages,
            setSelectedOrder,
            fullOrderHistory,
            moveToPageHandler
        }),
        [loading, selectedOrder, currentPage, totalPages, setSelectedOrder, fullOrderHistory, moveToPageHandler]
    );
    return <OrderHistoryContext.Provider value={value}>{children}</OrderHistoryContext.Provider>;
};

export default OrderHistoryProvider;

export const useOrderHistory = () => useContext(OrderHistoryContext);

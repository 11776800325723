import { FieldValidationRules } from '@wilm/shared-types/validation-rules/common';
import { FieldType } from '@wilm/shared-types/validation-rules/types';
import type { Fields, StringFieldDefinition } from '@wilm/shared-types/validation-rules/types';
import type { LearnerSeat } from '@wilm/shared-types/learner/Learner';

export interface OpenLearnersFields extends Fields {
    orderNumber: StringFieldDefinition;
}

export const initialOpenLearnersFields: Fields = {
    orderNumber: {
        name: 'orderNumber',
        type: FieldType.STRING,
        placeholder: 'A-AA-1234-5678',
        value: '',
        validation: {
            required: true,
            minLength: 2,
            maxLength: 100,
            errorMessages: {
                validation: 'error.missing.orderNumber',
                range: 'error.range.orderNumber'
            }
        }
    }
};

export const initialLearnerSeatFields: Fields = {
    firstName: {
        name: 'firstName',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.lastName.value || !!fields.email.value;
            },
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.firstName',
                range: 'error.range.firstName',
                regex: 'error.validation.firstName'
            }
        }
    },
    lastName: {
        name: 'lastName',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.firstName.value || !!fields.email.value;
            },
            regex: FieldValidationRules.NAME.REGEX,
            minLength: FieldValidationRules.NAME.MIN,
            maxLength: FieldValidationRules.NAME.MAX,
            errorMessages: {
                validation: 'error.missing.lastName',
                range: 'error.range.lastName',
                regex: 'error.validation.lastName'
            }
        }
    },
    email: {
        name: 'email',
        type: FieldType.STRING,
        value: '',
        validation: {
            requiredPredicate(fields) {
                return !!fields.firstName.value || !!fields.lastName.value;
            },
            regex: FieldValidationRules.EMAIL.REGEX,
            minLength: FieldValidationRules.EMAIL.MIN,
            maxLength: FieldValidationRules.EMAIL.MAX,
            errorMessages: {
                validation: 'error.missing.email',
                range: 'error.range.email',
                regex: 'error.validation.email'
            }
        }
    }
};

export const getLineItemLearnerSeatsDuplicatedEmails = (learnerSeats: Omit<LearnerSeat, 'provisioning'>[]) => {
    const emailSet = new Set();
    const duplicatedEmails: string[] = [];
    learnerSeats.forEach(learnerSeat => {
        if (emailSet.has(learnerSeat.learner.email)) {
            duplicatedEmails.push(learnerSeat.learner.email);
        } else {
            emailSet.add(learnerSeat.learner.email);
        }
    });
    //remove empty strings from duplicatedEmails and return
    return duplicatedEmails.filter(Boolean);
};

import type { FC } from 'react';
import React from 'react';
import type { Order } from '@wilm/shared-types/cart/Order';
import { PaymentMethodType } from '@wilm/shared-types/cart/Payment';
import Skeleton from 'react-loading-skeleton';
import Typography from 'components/commercetools-ui/atoms/typography';
import OrderItem from 'components/commercetools-ui/organisms/order-items-listing/order-item';
import Costs from 'components/commercetools-ui/organisms/order-payment-section/components/costs';
import { formatDate } from 'helpers/dateHelpers';
import { useFormat } from 'helpers/hooks/useFormat';
import { useCountries } from 'providers/countries';
import useBundle from 'frontastic/hooks/useBundle';
import useOrderData from '../helper-hooks/useOrderData';

export interface Props {
    order?: Order;
    orderState: string;
    asSkeleton?: boolean;
}

const OrderInfoSection: FC<Props> = ({ order, orderState, asSkeleton }) => {
    const { formatMessage: formatOrdersMessage } = useFormat({ name: 'orders' });
    const { paymentInfo } = useOrderData(order);
    const { countries } = useCountries();

    const { nonBundleItems: lineItems, bundleComponents } = useBundle(order?.lineItems ?? []);

    if (asSkeleton) {
        return (
            <div className="h-fit w-full 2xl:w-3/5">
                <div className="border border-input-disabled p-16 md:p-24 lg:p-30">
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22 mt-30" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                </div>
                <div className="mt-30 border border-input-disabled p-16 md:p-24 lg:p-30 2xl:hidden">
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                    <Skeleton containerClassName="w-full h-22" />
                </div>
            </div>
        );
    } else if (order) {
        const creationDate = order?.createdAt;
        const formattedCreationDate = creationDate && formatDate(creationDate);
        const billingAddress = order.billingAddress!;
        const country = countries?.find(country => country.value === billingAddress.country)?.label;
        return (
            <div className="h-fit w-full 2xl:w-3/5">
                <div className="border border-input-disabled p-16 md:p-24 lg:mx-0 lg:p-30">
                    {formattedCreationDate && (
                        <div className="flex">
                            <Typography className="text-md font-bold text-primary-black md:text-base">
                                {formatOrdersMessage({ id: 'order.created.date', defaultMessage: 'Created date:' })}
                            </Typography>
                            <Typography className="pl-5 text-md text-primary-black md:text-base">{formattedCreationDate}</Typography>
                        </div>
                    )}
                    {orderState && (
                        <div className="flex">
                            <Typography className="text-md font-bold text-primary-black md:text-base">
                                {formatOrdersMessage({ id: 'order.status', defaultMessage: 'Status:' })}
                            </Typography>
                            <Typography className="pl-5 text-md text-primary-black md:text-base">{orderState}</Typography>
                        </div>
                    )}
                    {order.custom?.fields?.poNumber && (
                        <div className="flex">
                            <Typography className="text-md font-bold text-primary-black md:text-base">
                                {formatOrdersMessage({ id: 'order.purchase.number', defaultMessage: 'Purchase order number:' })}
                            </Typography>
                            <Typography className="pl-5 text-md text-primary-black md:text-base">
                                {order.custom?.fields?.poNumber}
                            </Typography>
                        </div>
                    )}
                    <div className="mt-24 flex">
                        <div className="w-1/2">
                            <Typography
                                className="mb-8 whitespace-nowrap text-md font-bold text-secondary-black md:text-base"
                                data-cy="order-details-billing-address-label"
                            >
                                {formatOrdersMessage({
                                    id: 'billing.address',
                                    defaultMessage: 'Billing address:'
                                })}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">
                                {`${billingAddress.firstName} ${billingAddress.lastName}`}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">
                                {`${billingAddress.streetName}, ${billingAddress.city}`}
                            </Typography>
                            <Typography className="mb-8 text-md text-primary-black md:text-base">{billingAddress.postalCode}</Typography>
                            <Typography
                                className="mb-8 text-md text-primary-black md:text-base"
                                data-cy="order-details-billing-address-country"
                            >
                                {country}
                            </Typography>
                            <Typography className=" text-md text-primary-black md:text-base">{billingAddress.phone}</Typography>
                        </div>
                        <div className="w-1/2">
                            <Typography
                                className="mb-8 whitespace-nowrap text-md font-bold text-secondary-black md:text-base"
                                data-cy="order-details-payment-method-label"
                            >
                                {formatOrdersMessage({
                                    id: 'payment.method',
                                    defaultMessage: 'Payment method:'
                                })}
                            </Typography>

                            <Typography className="text-md text-primary-black md:text-base">
                                {paymentInfo?.type === PaymentMethodType.CARD
                                    ? formatOrdersMessage({ id: 'credit.card', defaultMessage: 'Credit/debit card' })
                                    : ''}
                            </Typography>
                            <Typography
                                className="text-md capitalize text-primary-black md:text-base"
                                data-cy="order-details-payment-method"
                            >
                                {paymentInfo?.label ?? ''}
                            </Typography>
                        </div>
                    </div>
                </div>
                <div className="mt-30 border border-input-disabled p-16 md:p-24 lg:p-30 2xl:hidden">
                    <div className="pb-16">
                        <Typography className="mb-6 font-bold">
                            {formatOrdersMessage({ id: 'your.order', defaultMessage: 'Your order' })}
                        </Typography>
                        {lineItems?.map(lineItem => (
                            <OrderItem
                                key={lineItem.lineItemId}
                                lineItem={lineItem}
                                bundleItems={bundleComponents?.[lineItem.lineItemId]}
                                isLastItem={order?.lineItems?.indexOf(lineItem) === (order?.lineItems?.length ?? 0) - 1}
                            />
                        ))}
                    </div>
                    <Costs order={order} className="border-t" dataReference="order" />
                </div>
            </div>
        );
    }
};

export default OrderInfoSection;

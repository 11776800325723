import React, { useCallback, useMemo, useState, useEffect } from 'react';
import * as yup from 'yup';
import Button from 'components/commercetools-ui/atoms/button';
import Checkbox from 'components/commercetools-ui/atoms/checkbox';
import B2BAddress from 'components/commercetools-ui/organisms/account/sections/addresses/b2b-address';
import AddressModal from 'components/commercetools-ui/organisms/checkout/components/address-modal';
import ErrorModal from 'components/commercetools-ui/organisms/checkout/components/error-modal';
import Markdown from 'components/commercetools-ui/organisms/markdown';
import track from 'helpers/gtm';
import TagsActionType from 'helpers/gtm/actions/types/tagsActionType';
import { useFormat } from 'helpers/hooks/useFormat';
import useProcessing from 'helpers/hooks/useProcessing';
import { useAccount, useCart } from 'frontastic';
import type { CartDetails } from 'frontastic/hooks/useCart/types';
import AccountAddresses from './components/account-addresses';
import useMappers from './hooks/useMappers';
import type { Address } from './types';

export interface Props {
    goToNextStep: () => void;
    sameAsBillingAddress: boolean;
    setSameAsBillingAddress: (value: boolean) => void;
    isLearnerStepEnabled: boolean;
    b2bCustomerInfoInvoiceSending: string;
    billingAddressMessage: string;
}

const Addresses: React.FC<Props> = ({
    goToNextStep,
    sameAsBillingAddress,
    setSameAsBillingAddress,
    isLearnerStepEnabled,
    b2bCustomerInfoInvoiceSending,
    billingAddressMessage
}) => {
    const { formatMessage: formatCheckoutMessage } = useFormat({ name: 'checkout' });
    const { formatMessage: formatCartMessage } = useFormat({ name: 'cart' });
    const { formatMessage: formatAccountMessage } = useFormat({ name: 'account' });

    const [isOpen, setIsOpen] = useState(false);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const { account, billingAddresses, defaultBillingAddress, shippingAddresses, defaultShippingAddress } = useAccount();
    const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);

    const { data: cartData, updateCart } = useCart();

    const { addressToAccountAddress, accountAddressToAddress } = useMappers();

    const initialAddressData = {
        phone: '',
        line1: '',
        line2: '',
        postalCode: '',
        city: '',
        country: ''
    } as Address;

    const [shippingAddress, setShippingAddress] = useState(initialAddressData);
    const [billingAddress, setBillingAddress] = useState(initialAddressData);
    const hasShipping = cartData?.lineItems?.some(lineItem => lineItem.variant?.attributes?.isShippingRequired);

    const currentShippingAddress = useMemo(
        () => (sameAsBillingAddress ? billingAddress : shippingAddress),
        [sameAsBillingAddress, shippingAddress, billingAddress]
    );
    const addressValidationSchema = useMemo(() => {
        if (account?.isB2B) {
            return yup.object().shape({
                line1: yup.string().required(),
                line2: yup.string().optional(),
                postalCode: yup.string(),
                city: yup.string().required(),
                phone: yup.string()
            });
        }
        return yup.object().shape({
            line1: yup.string().required(),
            line2: yup.string().optional(),
            postalCode: yup.string().required(),
            city: yup.string().required(),
            phone: yup.string().required()
        });
    }, [account?.isB2B]);

    const isValidShippingAddress = useMemo(() => {
        try {
            addressValidationSchema.validateSync(currentShippingAddress);
            return true;
        } catch (err) {
            return false;
        }
    }, [addressValidationSchema, currentShippingAddress]);

    const isValidBillingAddress = useMemo(() => {
        try {
            addressValidationSchema.validateSync(billingAddress);
            return true;
        } catch (err) {
            return false;
        }
    }, [addressValidationSchema, billingAddress]);

    const { processing, startProcessing, stopProcessing } = useProcessing();

    const submit = useCallback(async () => {
        if (!isValidShippingAddress || !isValidBillingAddress || processing) return;
        startProcessing();

        const data = {
            account: { email: account?.email },
            shipping: addressToAccountAddress(currentShippingAddress),
            billing: addressToAccountAddress(billingAddress)
        } as CartDetails;

        const res = await updateCart(data);

        track({
            type: TagsActionType.ADD_SHIPPING_INFO,
            payload: { cart: cartData!, shippingMethod: 'Digital shipping' }
        });

        stopProcessing();

        if (res.cartId) {
            goToNextStep();
        } else {
            setIsErrorModalOpen(true);
        }
    }, [
        account,
        isValidShippingAddress,
        isValidBillingAddress,
        billingAddress,
        currentShippingAddress,
        addressToAccountAddress,
        updateCart,
        goToNextStep,
        formatCheckoutMessage,
        processing,
        startProcessing,
        stopProcessing
    ]);
    useEffect(() => {
        if (account?.isB2B && defaultBillingAddress) {
            setBillingAddress(accountAddressToAddress(defaultBillingAddress));
            return; // No need to continue further if the account is B2B
        }

        // Handling for non-B2B (B2C) cases
        const cartBillingAddressId = cartData?.billingAddress?.addressId;

        if (billingAddresses && cartBillingAddressId) {
            const matchedAddress = billingAddresses.find(address => address.addressId === cartBillingAddressId);

            if (matchedAddress) {
                setBillingAddress(accountAddressToAddress(matchedAddress));
                return;
            }
        }

        // Set default billing address if no matching address was found
        if (defaultBillingAddress) {
            setBillingAddress(accountAddressToAddress(defaultBillingAddress));
        }
    }, [cartData?.billingAddress?.addressId, billingAddresses, defaultBillingAddress, account?.isB2B]);

    useEffect(() => {
        if (shippingAddresses && cartData?.shippingAddress?.addressId) {
            for (const address of shippingAddresses) {
                if (address.addressId === cartData?.shippingAddress?.addressId) {
                    setShippingAddress(accountAddressToAddress(address));
                    return;
                }
            }
        } else if (defaultShippingAddress) {
            setShippingAddress(accountAddressToAddress(defaultShippingAddress));
        }
    }, [cartData?.shippingAddress?.addressId, shippingAddresses, defaultShippingAddress]);

    const formattedBillingAddressMessage = useMemo(() => {
        return billingAddressMessage && account?.companyName ? billingAddressMessage.replace('{accountName}', account?.companyName) : '';
    }, [account?.companyName, billingAddressMessage]);

    return (
        <div className="bg-white pt-16 lg:px-30 lg:pb-36 lg:pt-0">
            {billingAddresses.length > 0 && (
                <div className="mt-20">
                    <h5 className="text-18">{formatCheckoutMessage({ id: 'billingAddress', defaultMessage: 'Billing Address' })}</h5>
                    {account?.isB2B ? (
                        <div>
                            <p className="my-20 text-16">
                                <Markdown markdown={b2bCustomerInfoInvoiceSending} />
                            </p>
                            {defaultBillingAddress && (
                                <>
                                    <Markdown markdown={formattedBillingAddressMessage} className="my-20" />{' '}
                                    <B2BAddress address={defaultBillingAddress} />{' '}
                                </>
                            )}
                        </div>
                    ) : (
                        <AccountAddresses
                            className="mt-20"
                            type="billing"
                            onSelectAddress={address => setBillingAddress(address)}
                            selectedAddress={billingAddress}
                            hasError={!isValidBillingAddress}
                        />
                    )}
                </div>
            )}

            {billingAddresses.length === 0 || (account?.isB2B && !defaultBillingAddress) ? (
                <> </>
            ) : (
                <div className="mt-48">
                    {(hasShipping ?? !sameAsBillingAddress) && (
                        <>
                            <div className="flex items-center gap-8 lg:gap-12">
                                <h5 className="text-18">
                                    {formatCheckoutMessage({ id: 'shippingAddress', defaultMessage: 'Shipping Address' })}
                                </h5>
                            </div>

                            <div className="mt-28 flex items-center gap-12 p-2">
                                <Checkbox
                                    label={formatCheckoutMessage({
                                        id: 'shippingDetailsLabel',
                                        defaultMessage: 'My shipping address is the same as my billing address'
                                    })}
                                    labelPosition="on-right"
                                    checked={sameAsBillingAddress}
                                    onChange={({ checked }) => setSameAsBillingAddress(checked)}
                                    disableBackground
                                />
                            </div>
                        </>
                    )}

                    {!sameAsBillingAddress && (
                        <AccountAddresses
                            type="shipping"
                            className="mt-28"
                            selectedAddress={shippingAddress}
                            onSelectAddress={address => setShippingAddress(address)}
                        />
                    )}
                </div>
            )}

            <div className="mt-28 md:mt-36 lg:mt-45">
                {(billingAddresses.length && !account?.isB2B) || (account?.isB2B && defaultBillingAddress) ? (
                    <Button
                        variant="primary"
                        className="w-full min-w-200 md:text-16 lg:w-fit lg:px-36"
                        disabled={!isValidShippingAddress || !isValidBillingAddress}
                        loading={processing}
                        type="submit"
                        onClick={submit}
                        data-cy="continue-to-payment"
                    >
                        {formatCartMessage({ id: 'continue.to', defaultMessage: 'Continue to' })}{' '}
                        {isLearnerStepEnabled ? (
                            <span className="lowercase">
                                {' '}
                                {formatCartMessage({ id: 'learner.details', defaultMessage: 'Learner Details' })}
                            </span>
                        ) : (
                            <span className="lowercase">{formatCartMessage({ id: 'payment', defaultMessage: 'Payment' })}</span>
                        )}
                    </Button>
                ) : (
                    <>
                        <Button
                            variant="primary"
                            className="w-full min-w-200 md:text-16 lg:w-fit lg:px-36"
                            onClick={() => setIsOpen(true)}
                            data-cy="add-new-address"
                        >
                            {formatAccountMessage({ id: 'address.add', defaultMessage: 'Add new address' })}
                        </Button>
                        <AddressModal isOpen={isOpen} closeModal={closeModal} setSameAsBillingAddress={setSameAsBillingAddress} />
                    </>
                )}
            </div>
            {!isValidBillingAddress && (
                <div className="mt-10">
                    <p className="text-14 text-red-500">
                        {formatCheckoutMessage({
                            id: 'billing.address.error',
                            defaultMessage: 'The selected billing address is not valid. Add new address or edit the selected.'
                        })}
                    </p>
                </div>
            )}
            <ErrorModal isErrorModalOpen={isErrorModalOpen} setIsErrorModalOpen={setIsErrorModalOpen} />
        </div>
    );
};

export default Addresses;
